import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';

export class Template {

    protected _uuid: string;

    protected _categoryName: string;

    protected _htmlTemplate: MultilingualString;

    protected _name: MultilingualString;

    protected _description: MultilingualString;

    protected _updatedBy: number;

    protected _updatedOn: Date;

    constructor(uuid: string, categoryName: string, htmlTemplate: MultilingualString,
                name: MultilingualString, description: MultilingualString, updatedBy: number, updatedOn: Date) {

        this._uuid = uuid;
        this._categoryName = categoryName;
        this._htmlTemplate = htmlTemplate;
        this._name = name;
        this._description = description;
        this._updatedBy = updatedBy;
        this._updatedOn = updatedOn;
    }


    get uuid(): string {
        return this._uuid;
    }

    set uuid(value: string) {
        this._uuid = value;
    }

    get categoryName(): string {
        return this._categoryName;
    }

    set categoryName(value: string) {
        this._categoryName = value;
    }

    get htmlTemplate(): MultilingualString {
        return this._htmlTemplate;
    }

    set htmlTemplate(value: MultilingualString) {
        this._htmlTemplate = value;
    }

    get name(): MultilingualString {
        return this._name;
    }

    set name(value: MultilingualString) {
        this._name = value;
    }

    get description(): MultilingualString {
        return this._description;
    }

    set description(value: MultilingualString) {
        this._description = value;
    }

    get updatedBy(): number {
        return this._updatedBy;
    }

    set updatedBy(value: number) {
        this._updatedBy = value;
    }

    get updatedOn(): Date {
        return this._updatedOn;
    }

    set updatedOn(value: Date) {
        this._updatedOn = value;
    }

    public toJSON(): string {
        return JSON.stringify(this.toJsonObject());
    }

    public toJsonObject(): object {
        return {
            uuid: this._uuid,
            categoryName: this._categoryName,
            name: this.convertMultilingualStringToObject(this._name),
            description: this.convertMultilingualStringToObject(this._description),
            htmlTemplate: this.convertMultilingualStringToObject(this._htmlTemplate)
        };
    }

    protected convertMultilingualStringToObject(multilingualString: MultilingualString): object {
        const obj = {};
        multilingualString.toMap().forEach((value: string, key: number) => {
            obj[key] = value;
        });
        return obj;
    }
}
