import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {Template} from './template.model';

export class PersonalizedTemplate extends Template {

    private _originalTemplateUUId: string;

    private _formId: number;

    private _createdOn: Date;

    private _exportType: number;


    constructor(uuid: string, categoryName: string, htmlTemplate: MultilingualString, name: MultilingualString,
                description: MultilingualString, updatedBy: number, updatedOn: Date,
                originalTemplateUUId: string, formId: number, createdOn: Date, exportType: number) {
        super(uuid, categoryName, htmlTemplate, name, description, updatedBy, updatedOn);
        this._originalTemplateUUId = originalTemplateUUId;
        this._formId = formId;
        this._createdOn = createdOn;
        this._exportType = exportType;
    }

    get originalTemplateUUId(): string {
        return this._originalTemplateUUId;
    }

    set originalTemplateUUId(value: string) {
        this._originalTemplateUUId = value;
    }

    get formId(): number {
        return this._formId;
    }

    set formId(value: number) {
        this._formId = value;
    }

    get createdOn(): Date {
        return this._createdOn;
    }

    set createdOn(value: Date) {
        this._createdOn = value;
    }

    get exportType(): number {
        return this._exportType;
    }

    set exportType(value: number) {
        this._exportType = value;
    }

    public toJsonObject(): object {
        return {
            uuid: this._uuid,
            originalTemplateUUId: this._originalTemplateUUId,
            formId: this._formId,
            categoryName: this._categoryName,
            exportType: this._exportType,
            name: this.convertMultilingualStringToObject(this._name),
            description: this.convertMultilingualStringToObject(this._description),
            htmlTemplate: this.convertMultilingualStringToObject(this._htmlTemplate)
        };
    }
}
