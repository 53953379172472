import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import {Observable} from 'rxjs/Observable';
import {map, switchMap} from 'rxjs/operators';
import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {PagingOptions, SortingOptions} from '@synisys/idm-de-core-frontend';
import {StringTemplate} from '@synisys/idm-common-util-frontend';
import {HttpClientWrapper} from '@synisys/idm-authentication-client-js';
import {ExportTemplatesService} from '../service/export-templates.service';
import {
    createPersonalizedTemplateDto, createPersonalizedTemplatesDataDto, createTemplateDto,
    createTemplatesDataDto
} from '../helper/dto.helper';
import {Template} from '../model/template.model';
import {TemplatesData} from '../model/templates-data.model';
import {CategoryTemplateViewModel} from '../model/category-template-view.model';
import {PersonalizedTemplate} from '../model/personalized-template.model';

@Injectable()
export class HttpExportTemplatesService implements ExportTemplatesService {

    public URL_TEMPLATE_LOAD: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/templates/${'uuid'}`;
    public URL_TEMPLATES_LOAD: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/templates`;
    public URL_TEMPLATE_CREATE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/templates`;
    public URL_TEMPLATE_DELETE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/templates/${'uuid'}`;
    public URL_TEMPLATE_UPDATE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/templates/${'uuid'}`;
    public URL_TEMPLATE_GET_XML: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/templates/${'uuid'}/xml`;
    public URL_TEMPLATES_LOAD_BY_CATEGORY: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/templates/${'categoryName'}/name`;

    // personalized template urls
    public URL_PERSONALIZED_TEMPLATE_LOAD: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/personalized-templates/${'uuid'}`;
    public URL_PERSONALIZED_TEMPLATES_LOAD: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/personalized-templates`;
    public URL_PERSONALIZED_TEMPLATE_CREATE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/personalized-templates`;
    public URL_PERSONALIZED_TEMPLATE_DELETE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/personalized-templates/${'uuid'}`;
    public URL_PERSONALIZED_TEMPLATE_UPDATE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/personalized-templates/${'uuid'}`;

    private exportTemplatesServiceUri = 'export-templates-service-url';

    private exportTemplatesServiceUri$: Observable<string>;

    constructor(private http: HttpClientWrapper,
                private applicationPropertiesService: ApplicationPropertiesService) {

        this.exportTemplatesServiceUri$ = Observable.from(this.applicationPropertiesService.getProperty(this.exportTemplatesServiceUri));
    }

    public loadTemplate(uuid: string): Observable<Template> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_TEMPLATE_LOAD.replaceTemplate({
                        serviceUri, uuid
                    });
                    return this.http.get(url);
                }),
                map(data => createTemplateDto(data))
            );
    }

    public loadTemplates(languageId: number, paging?: PagingOptions, sorting?: SortingOptions): Observable<TemplatesData> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_TEMPLATES_LOAD.replaceTemplate({serviceUri});
                    let httpParams: HttpParams = new HttpParams().set('languageId', languageId.toString());
                    if (paging) {
                        httpParams = httpParams.append('pagingCriteria', JSON.stringify(paging));
                    }
                    if (sorting) {
                        httpParams = httpParams.append('sortingCriteria', JSON.stringify(sorting));
                    }
                    return this.http.get(url, {params: httpParams});
                }),
                map(data => createTemplatesDataDto(data))
            );
    }

    public createTemplate(template: Template): Observable<Template> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_TEMPLATE_CREATE.replaceTemplate({serviceUri});
                    const headers = new HttpHeaders().append('Content-Type', 'application/json');
                    return this.http.post(url, template.toJSON(), {headers});
                }),
                map(data => createTemplateDto(data))
            );
    }

    public updateTemplate(template: Template): Observable<Template> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_TEMPLATE_UPDATE.replaceTemplate({
                        serviceUri, uuid: template.uuid
                    });
                    const headers = new HttpHeaders().append('Content-Type', 'application/json');
                    return this.http.put(url, template.toJSON(), {headers});
                }),
                map(data => createTemplateDto(data))
            );
    }

    public deleteTemplate(uuid: string): Observable<any> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_TEMPLATE_DELETE.replaceTemplate({
                        serviceUri, uuid
                    });
                    return this.http.delete(url);
                })
            );
    }

    public getXMLTemplate(uuid: string, languageId: number): Observable<string> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_TEMPLATE_GET_XML.replaceTemplate({
                        serviceUri, uuid
                    });
                    const httpParams: HttpParams = new HttpParams().set('languageId', languageId.toString());
                    return this.http.get(url, {params: httpParams, responseType: 'text'});
                }),
                map(data => <string>data)
            );
    }

    public loadTemplatesDataByCategoryName(categoryName: string, languageId: number): Observable<CategoryTemplateViewModel[]> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_TEMPLATES_LOAD_BY_CATEGORY.replaceTemplate({
                        serviceUri, categoryName
                    });
                    const httpParams: HttpParams = new HttpParams().set('languageId', languageId.toString());
                    return this.http.get(url, {params: httpParams});
                }),
                map((templates: any[]) => templates.map((template) => {
                    return CategoryTemplateViewModel.fromJSON(template);
                }))
            );
    }

    public loadPersonalizedTemplate(uuid: string): Observable<PersonalizedTemplate> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_PERSONALIZED_TEMPLATE_LOAD.replaceTemplate({
                        serviceUri, uuid
                    });
                    return this.http.get(url);
                }),
                map(data => createPersonalizedTemplateDto(data))
            );
    }

    public loadPersonalizedTemplates(languageId: number, formId?: number, paging?: PagingOptions,
                                     sorting?: SortingOptions): Observable<TemplatesData> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_PERSONALIZED_TEMPLATES_LOAD.replaceTemplate({serviceUri});
                    let httpParams: HttpParams = new HttpParams().set('languageId', languageId.toString());
                    if (formId) {
                        httpParams = httpParams.append('formId', JSON.stringify(formId));
                    }
                    if (paging) {
                        httpParams = httpParams.append('pagingCriteria', JSON.stringify(paging));
                    }
                    if (sorting) {
                        httpParams = httpParams.append('sortingCriteria', JSON.stringify(sorting));
                    }
                    return this.http.get(url, {params: httpParams});
                }),
                map(data => createPersonalizedTemplatesDataDto(data))
            );
    }

    public createPersonalizedTemplate(template: PersonalizedTemplate): Observable<PersonalizedTemplate> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_PERSONALIZED_TEMPLATE_CREATE.replaceTemplate({serviceUri});
                    const headers = new HttpHeaders().append('Content-Type', 'application/json');
                    return this.http.post(url, template.toJSON(), {headers});
                }),
                map(data => createPersonalizedTemplateDto(data))
            );
    }

    public updatePersonalizedTemplate(template: PersonalizedTemplate): Observable<PersonalizedTemplate> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_PERSONALIZED_TEMPLATE_UPDATE.replaceTemplate({
                        serviceUri, uuid: template.uuid
                    });
                    const headers = new HttpHeaders().append('Content-Type', 'application/json');
                    return this.http.put(url, template.toJSON(), {headers});
                }),
                map(data => createPersonalizedTemplateDto(data))
            );
    }

    public deletePersonalizedTemplate(uuid: string): Observable<any> {
        return this.exportTemplatesServiceUri$
            .pipe(
                switchMap(serviceUri => {
                    const url = this.URL_PERSONALIZED_TEMPLATE_DELETE.replaceTemplate({
                        serviceUri, uuid
                    });
                    return this.http.delete(url);
                })
            );
    }
}
