import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {TemplatesData} from '../model/templates-data.model';
import {TemplateView} from '../model/template-view.model';
import {Template} from '../model/template.model';
import {PersonalizedTemplate} from '../model/personalized-template.model';
import {PersonalizedTemplateView} from '../model/personalized-template-view.model';

export function createTemplateDto(data: any): Template {
    const uuid = data.uuId;
    const categoryName = data.categoryName;
    const htmlTemplate = new MultilingualString(convertObjectToMap(data.htmlTemplate));
    const name = new MultilingualString(convertObjectToMap(data.name));
    const description = new MultilingualString(convertObjectToMap(data.description));
    const updatedBy = Number(data.updatedBy);
    const updatedOn = new Date(Number(data.updatedOn));
    return new Template(uuid, categoryName, htmlTemplate, name, description, updatedBy, updatedOn);
}

export function createTemplateViewDto(data: any): TemplateView {
    const uuid = data.uuId;
    const categoryName = data.categoryName;
    const name = data.name;
    const description = data.description;
    const updatedBy = Number(data.updatedBy);
    const updatedOn = new Date(Number(data.updatedOn));
    return new TemplateView(uuid, categoryName, name, description, updatedBy, updatedOn);
}

export function createPersonalizedTemplateDto(data: any): PersonalizedTemplate {
    const uuid = data.uuId;
    const originalTemplateUUId = data.originalTemplateUUId;
    const categoryName = data.categoryName;
    const formId = data.formId;
    const htmlTemplate = new MultilingualString(convertObjectToMap(data.htmlTemplate));
    const name = new MultilingualString(convertObjectToMap(data.name));
    const description = new MultilingualString(convertObjectToMap(data.description));
    const updatedBy = Number(data.updatedBy);
    const exportType = Number(data.exportType);
    const updatedOn = new Date(Number(data.updatedOn));
    const createdOn = new Date(Number(data.createdOn));
    return new PersonalizedTemplate(uuid, categoryName, htmlTemplate, name, description,
        updatedBy, updatedOn, originalTemplateUUId, formId, createdOn, exportType);
}

export function createPersonalizedTemplateViewDto(data: any): PersonalizedTemplateView {
    const uuid = data.uuId;
    const originalTemplateUUId = data.originalTemplateUUId;
    const formId = data.formId;
    const categoryName = data.categoryName;
    const name = data.name;
    const description = data.description;
    const updatedBy = Number(data.updatedBy);
    const exportType = Number(data.exportType);
    const updatedOn = new Date(Number(data.updatedOn));
    const createdOn = new Date(Number(data.createdOn));

    return new PersonalizedTemplateView(uuid, categoryName, name, description, updatedBy,
        updatedOn, originalTemplateUUId, formId, createdOn, exportType);
}

export function createTemplatesDataDto(data: any): TemplatesData {
    const templates = data.templateValues && data.templateValues.length
        ? data.templateValues.map((template: any) => createTemplateViewDto(template)) : [];
    return new TemplatesData(data.count, templates);
}

export function createPersonalizedTemplatesDataDto(data: any): TemplatesData {
    const templates = data.templateValues && data.templateValues.length
        ? data.templateValues.map((template: any) => createPersonalizedTemplateViewDto(template)) : [];
    return new TemplatesData(data.count, templates);
}

export function convertObjectToMap(obj: Object): Map<number, string> {
    const strMap = new Map<number, string>();
    for (const prop of Object.keys(obj)) {
        strMap.set(Number(prop), obj[prop]);
    }
    return strMap;
}
