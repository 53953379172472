import {TemplateView} from './template-view.model';
import {PersonalizedTemplateView} from './personalized-template-view.model';

export class TemplatesData {

    private _count: number;

    private _templateViews: Array<TemplateView | PersonalizedTemplateView>;

    constructor(count: number, templateViews: Array<TemplateView | PersonalizedTemplateView>) {
        this._count = count;
        this._templateViews = templateViews;
    }

    get count(): number {
        return this._count;
    }

    set count(value: number) {
        this._count = value;
    }

    get templateViews(): Array<TemplateView | PersonalizedTemplateView> {
        return this._templateViews;
    }

    set templateViews(value: Array<TemplateView | PersonalizedTemplateView>) {
        this._templateViews = value;
    }
}
