import {NgModule} from '@angular/core';
import {ExportTemplatesService} from './service/export-templates.service';
import {HttpExportTemplatesService} from './http/http-export-templates.service';

@NgModule({
    providers: [
        {provide: ExportTemplatesService, useClass: HttpExportTemplatesService}
    ]
})
export class ExportTemplatesServiceModule {
}
