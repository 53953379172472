export class TemplateView {

    protected _uuid: string;

    protected _categoryName: string;

    protected _name: string;

    protected _description: string;

    protected _updatedBy: number;

    protected _updatedOn: Date;

    constructor(uuid: string, categoryName: string, name: string, description: string, updatedBy: number, updatedOn: Date) {
        this._uuid = uuid;
        this._categoryName = categoryName;
        this._name = name;
        this._description = description;
        this._updatedBy = updatedBy;
        this._updatedOn = updatedOn;
    }

    get uuid(): string {
        return this._uuid;
    }

    set uuid(value: string) {
        this._uuid = value;
    }

    get categoryName(): string {
        return this._categoryName;
    }

    set categoryName(value: string) {
        this._categoryName = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get updatedBy(): number {
        return this._updatedBy;
    }

    set updatedBy(value: number) {
        this._updatedBy = value;
    }

    get updatedOn(): Date {
        return this._updatedOn;
    }

    set updatedOn(value: Date) {
        this._updatedOn = value;
    }
}
