// tslint:disable-next-line:import-blacklist
import {Observable} from 'rxjs/Observable';
import {Template} from '../model/template.model';
import {PagingOptions, SortingOptions} from '@synisys/idm-de-core-frontend';
import {TemplatesData} from '../model/templates-data.model';
import {CategoryTemplateViewModel} from '../model/category-template-view.model';
import {PersonalizedTemplate} from '../model/personalized-template.model';

/**
 * Abstract Class Interface responsible for loading and modifying export template item.
 *
 * @author Tatevik.R.Gevorgyan
 */
export abstract class ExportTemplatesService {

    /**
     * Loads template by uuid
     * @param uuid
     */
    public abstract loadTemplate(uuid: string): Observable<Template>;

    /**
     * Loads templates data by current language, paging and sorting criterias
     * @param languageId
     * @param paging
     * @param sorting
     */
    public abstract loadTemplates(languageId: number, paging?: PagingOptions, sorting?: SortingOptions): Observable<TemplatesData>;

    /**
     * Creates a new template
     * @param template
     */
    public abstract createTemplate(template: Template): Observable<Template>;

    /**
     * Updates template
     * @param template
     */
    public abstract updateTemplate(template: Template): Observable<Template>;

    /**
     * Deletes template by uuid
     * @param uuid
     */
    public abstract deleteTemplate(uuid: string): Observable<any>;

    /**
     * Loads XML template by uuid
     * @param uuid
     * @param languageId
     */
    public abstract getXMLTemplate(uuid: string, languageId: number): Observable<any>;

    /**
     * Loads templates data by category name
     * @param categoryName
     * @param languageId
     */
    public abstract loadTemplatesDataByCategoryName(categoryName: string, languageId: number): Observable<CategoryTemplateViewModel[]>;

    /**
     * Loads personalized template by uuid
     * @param uuid
     */
    public abstract loadPersonalizedTemplate(uuid: string): Observable<PersonalizedTemplate>;

    /**
     * Loads personalized templates data by current language, formId, paging and sorting criterias
     * @param languageId
     * @param formId
     * @param paging
     * @param sorting
     */
    public abstract loadPersonalizedTemplates(languageId: number, formId?: number, paging?: PagingOptions,
                                              sorting?: SortingOptions): Observable<TemplatesData>;

    /**
     * Creates a new personalized template
     * @param template
     */
    public abstract createPersonalizedTemplate(template: PersonalizedTemplate): Observable<PersonalizedTemplate>;

    /**
     * Updates personalized template
     * @param template
     */
    public abstract updatePersonalizedTemplate(template: PersonalizedTemplate): Observable<PersonalizedTemplate>;

    /**
     * Deletes personalized template by uuid
     * @param uuid
     */
    public abstract deletePersonalizedTemplate(uuid: string): Observable<any>;

}
